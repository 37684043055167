<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <app-logo />
        </b-link>
        <b-card-title class="mb-1">
          Sizin için buradayız! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Talep için lütfen hesabınıza giriş yapın.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="E-Posta"
            >
              <validation-provider
                #default="{ errors }"
                name="E-Posta"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Parola</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Parolamı unuttum?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Parola"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Parola"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Oturum Aç
            </b-button>
          </b-form>
        </validation-observer>
        <div class="auth-footer-btn d-flex justify-content-center mt-2">
          <b-button
            href="https://www.facebook.com/code64yazilim"
            target="_blank"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="https://twitter.com/code64yazilim"
            target="_blank"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="https://www.instagram.com/code64yazilim/"
            target="_blank"
            variant="instagram"
          >
            <feather-icon icon="InstagramIcon" />
          </b-button>
          <b-button
            href="https://www.linkedin.com/company/code64yaz%C4%B1l%C4%B1m/"
            target="_blank"
            variant="linkedin"
          >
            <feather-icon icon="LinkedinIcon" />
          </b-button>
          <b-button
            href="mailto:destek@code64.com.tr"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import AppLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    AppLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.setLocale()
  },
  methods: {
    setLocale() {
      const locale = localStorage.getItem('locale')
      if (locale) {
        this.$i18n.locale = locale
      }
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              if (response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Bir Hata Oluştu.!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: 'Oturum açma bilgileriniz hatalı, lütfen tekrar deneyiniz.!',
                  },
                })
              }
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              useJwt.setDownloadToken(response.data.downloadToken)
              localStorage.setItem('userData', JSON.stringify(userData))

              this.$ability.update(userData.ability)
              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              // console.log(getHomeRouteForLoggedInUser(userData.role))
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Merhaba ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Oturum açma işleminiz başarılı!',
                    },
                  })
                })
            })
            .catch(error => {
              // this.$refs.loginForm.setErrors(error.response.data.error)
              this.$refs.loginForm.setErrors(error)
            })
        }
      })
    },
    changeLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
